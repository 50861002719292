/* -------------------------------------------------------------------
// markItUp! Universal MarkUp Engine, JQuery plugin
// By Jay Salvat - http://markitup.jaysalvat.com/
// ------------------------------------------------------------------*/
.markItUp * {
	margin:0px; padding:0px;
	outline:none;
}
.markItUp a:link,
.markItUp a:visited {
	color:#000;
	text-decoration:none;
}
.markItUp  {
	width:100%;
	margin:5px 0 5px 0;
	border:5px solid #F5F5F5;	
}
.markItUpContainer  {
	border:1px solid #3C769D;	
	background:#FFF url(../images/bg-container.png) repeat-x top left;
	padding:5px 5px 2px 5px;
	font:11px Verdana, Arial, Helvetica, sans-serif;
}
.markItUpEditor {
	font:12px 'Courier New', Courier, monospace;
	padding:5px 5px 5px 35px;
	border:none;
	width:643px;
	height:320px;
	clear:both;
	line-height:18px;
	overflow:auto;
}
.markItUpPreviewFrame	{
	overflow:auto;
	background-color:#FFFFFF;
	border:1px solid #3C769D;
	width:99.9%;
	height:300px;
	margin:5px 0;
}
.markItUpFooter {
	width:100%;
	cursor:n-resize;
}
.markItUpResizeHandle {
	overflow:hidden;
	width:22px; height:5px;
	margin-left:auto;
	margin-right:auto;
	background-image:url(../images/handle.png);
	cursor:n-resize;
}
/***************************************************************************************/
/* first row of buttons */
.markItUpHeader ul li	{
	list-style:none;
	float:left;
	position:relative;
}
.markItUpHeader ul li ul{
	display:none;
}
.markItUpHeader ul li:hover > ul{
	display:block;
}
.markItUpHeader ul .markItUpDropMenu {
	background:transparent url(../images/menu.png) no-repeat 115% 50%;
	margin-right:5px;
}
.markItUpHeader ul .markItUpDropMenu li {
	margin-right:0px;
}
.markItUpHeader ul .markItUpSeparator {
	margin:0 10px;
	width:1px;
	height:16px;
	overflow:hidden;
	background-color:#CCC;
}
.markItUpHeader ul ul .markItUpSeparator {
	width:auto; height:1px;
	margin:0px;
}
/* next rows of buttons */
.markItUpHeader ul ul {
	display:none;
	position:absolute;
	top:18px; left:0px;	
	background:#F5F5F5;
	border:1px solid #3C769D;
	height:inherit;
}
.markItUpHeader ul ul li {
	float:none;
	border-bottom:1px solid #3C769D;
}
.markItUpHeader ul ul .markItUpDropMenu {
	background:#F5F5F5 url(../images/submenu.png) no-repeat 100% 50%;
}
/* next rows of buttons */
.markItUpHeader ul ul ul {
	position:absolute;
	top:-1px; left:150px;
}
.markItUpHeader ul ul ul li {
	float:none;
}
.markItUpHeader ul a {
	display:block;
	width:16px; height:16px;
	text-indent:-10000px;
	background-repeat:no-repeat;
	padding:12px;
	margin:0px;
}
.markItUpHeader ul ul a {
	display:block;
	padding-left:0px;
	text-indent:0;
	width:120px; 
	padding:5px 5px 5px 25px;
	background-position:2px 50%;
}
.markItUpHeader ul ul a:hover  {
	color:#FFF;
	background-color:#3C769D;
}
/***************************************************************************************/
.html .markItUpEditor {
	background-image:url(../images/bg-editor-html.png);
}

.textile .markItUpEditor {
	background-image:url(../images/bg-editor-textile.png);
}
.bbcode .markItUpEditor {
	background-image:url(../images/bg-editor-bbcode.png);
}
.wiki .markItUpEditor,
.dotclear .markItUpEditor {
	background-image:url(../images/bg-editor-wiki.png);
}